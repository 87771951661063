<template>
  <div class="col-md-12">
    <div class="card text-center card-container mt-5 p-3">

      <div>
        <div class="p-2">
          <span class="p-input-icon-left">
            <i class="pi pi-user"/>
              <InputText
                type="text"
                v-model="username"
                placeholder="Username"
                @keyup.enter="handleLogin"
                />
          </span>
        </div>

        <div class="p-2">
          <span class="p-input-icon-left">
            <i class="pi pi-lock"/>
              <InputText
                type="password"
                v-model="password"
                placeholder="Password"
                @keyup.enter="handleLogin"
                />
          </span>
        </div>

        <div class="mt-3">
          <Button
            label="submit"
            :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
            iconPos="left"
            @click="handleLogin"
            />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'

export default {
  name: 'LoginComponent',
  components: { },

  data() {
    return {
      loading: false,
      username: null,
      password: null
    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.authStore.status.loggedIn
    },
  },

  created() {
    if (this.loggedIn) {
      this.$router.push('/userHome')
    }
  },

  methods: {
    handleLogin() {
      this.loading = true
      this.$store.dispatch(
        "authStore/login",
        {
          username: this.username,
          password: this.password
        }
      ).then(
        () => {
          useToast().success(
            'Logged in succesfully',
            {
              timeout: 4000,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: false
            }
          )
          this.$router.push("/userHome")
        },
        (error) => {
          this.loading = false
          const message = (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) ||
            error.message ||
            error.toString()

          useToast().error(
            message,
            {
              timeout: 4000,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: false
            }
          )
        }
      )
    },
  },
}
</script>

<style scoped>
</style>
