import moment from 'moment'

const storedDevices = JSON.parse(localStorage.getItem('glimpseStoredDevices'));
const storedTimerange = JSON.parse(localStorage.getItem('glimpseStoredTimerange'));

const initialState = {
  selectedDevices: storedDevices ? storedDevices : [],
  selectedTimerange: storedTimerange ? storedTimerange : [
    moment('2022-01-31'),
    moment('2022-02-14')
  ]
}

export const searchPrefsStore = {
  namespaced: true,
  state: initialState,

  getters: {
    getEarliestDate(state) {
      // Should return a moment object.
      if (state.selectedTimerange) {
        return moment(state.selectedTimerange[0])
      } else {
        return moment('2022-01-31')
      }
    },
    getLatestDate(state) {
      // Should return a moment object.
      if (state.selectedTimerange) {
        return moment(state.selectedTimerange[1])
      } else {
        return moment('2022-02-14')
      }
    },
    getSelectedDevicesForCharts(state) {
      // Should return an Array of selected Device ID strings.
      if (state.selectedDevices) {
        let deviceIDs = Array()

        state.selectedDevices.forEach((e) => {
          deviceIDs.push(e.value)
        });

        return deviceIDs;
      } else {
        return []
      }
    },
    getSelectedDevicesForInput(state) {
      // Should return an Array of objs of selected Device ID strings and
      // names.
      if (state.selectedDevices) {
        return state.selectedDevices;
      } else {
        return []
      }
    },
  },

  mutations: {
    setSelectedDevices(state, selectedDevices) {
      // selectedDevices is a list of objs.
      state.selectedDevices = selectedDevices;
      localStorage.setItem('glimpseStoredDevices', JSON.stringify(selectedDevices))
    },
    setSelectedTimerange(state, selectedTimerange) {
      // List of 2 moment objs.
      state.selectedTimerange = selectedTimerange;
      localStorage.setItem('glimpseStoredTimerange', JSON.stringify(selectedTimerange))
    },
  }
}
