import axios from 'axios'

const API_URL = 'https://test-api.glimpse.ie/'

class AuthService {
  /**
   * This handles login/logout.
   */
  login(user) {
    return axios({
        method: 'POST',
        url: API_URL + 'auth',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        },
        data: {
          user: user.username,
          pass: user.password
        },
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem(
            'user',
            JSON.stringify({
              username: user.username,
              token: response.data.token
            })
          )
        }

        return response.data
      })
  }

  logout() {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
