<template>
  <img
    alt="Glimpse Logo"
    src="../assets/logo.png"
    height=60
    width=60
    />
  <div class="home">
    <LoginComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginComponent from '@/components/Login.vue'

export default {
  name: 'Home',

  components: {
    LoginComponent
  },

}
</script>
