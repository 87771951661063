import { createStore } from 'vuex'

import { authStore } from './AuthStore'
import { searchPrefsStore } from './SearchPrefsStore'
import { userPrefsStore } from './UserPrefsStore'

const store = createStore({
  modules: {
    authStore,
    searchPrefsStore,
    userPrefsStore,
  }
})

export default store
