import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueApexCharts from 'vue3-apexcharts'

import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Sidebar from 'primevue/sidebar'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueGridLayout from 'vue-grid-layout'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@/plugins/font-awesome'

import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf'


const app = createApp(App)

app.use(store)
app.use(router)

app.use(VueApexCharts)

app.use(PrimeVue, {ripple: true})
app.component('InputText', InputText)
app.component('Button', Button)
app.component('ProgressSpinner', ProgressSpinner)
app.component('Dropdown', Dropdown)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Sidebar', Sidebar)
app.component('Calendar', Calendar)
app.component('MultiSelect', MultiSelect)

app.use(Toast)

app.use(VueGridLayout)

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(Vue3SimpleHtml2pdf)

app.mount('#app')
