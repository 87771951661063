const themeID = JSON.parse(localStorage.getItem('themeID'));

const initialState = themeID
  ? { themeID: themeID }
  : { themeID: 'palette1' }

export const userPrefsStore = {
  namespaced: true,
  state: initialState,

  mutations: {
    setTheme(state, themeID) {
      localStorage.setItem('themeID', JSON.stringify(themeID))
      state.themeID = themeID
    },
  }
}
