<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <a href="/" class="navbar-brand">Glimpse</a>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <i class="pi pi-sign-in"/>
            Login
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">
            <i class="pi pi-chart-line"/>
            Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/userHome" class="nav-link">
            <i class="pi pi-user"/>
            {{ currentUser.username }}'s Profile
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logout">
            <i class="pi pi-sign-out"/>
            Logout
          </a>
        </li>
      </div>
    </nav>
    <div class="container2">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.authStore.user
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('authStore/logout')
      this.$router.push('/login')
    }
  }
}
</script>
